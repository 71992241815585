<template>
  <footer class="text-left">
    <div class="container px-md-0">
      <div class="row d-flex justify-content-center">
        <div class="col-md-4 mb-4 mb-md-0">
          <b-img class="logo mb-2 h-auto" alt="melo" :src="require('@/assets/logo/melo-black.svg')"></b-img>
          <ul class="opacity-low">
            <li>
              <b-link to="/privacy" @click="handleNavigation">Politique de confidentialité</b-link>
            </li>
            <li>
              <b-link to="/terms" @click="handleNavigation">CGUV</b-link>
            </li>
            <li>Paiement sécurisé</li>
          </ul>
          <div class="d-flex flex-row">
            <IconVisa class="mr-2" />
            <IconMastercard class="mr-2" />
            <IconCreditCard />
          </div>
        </div>
        <div class="col-md-8">
          <div class="row links">
            <div class="col-md-3">
              <h5>À propos</h5>
              <ul>
                <li>
                  <a target="_blank" rel="noopener" href="https://www.linkedin.com/company/melo-io">LinkedIn</a>
                </li>  
                <!--  <li>À propos</li> -->
                <li>
                  <b-link to="/actualites" @click="handleNavigation">Actualités</b-link>
                </li>     
                <li>
                  <b-link to="/recherche-immobilier" @click="handleNavigation">Recherche immobilier</b-link>
                </li>      
                <li>
                  <b-link to="/logiciel-api-immobilier" @click="handleNavigation">Logiciel API immobilier</b-link>
                </li>  
                <li>
                  <b-link to="/comparatif-logiciels-immobiliers" @click="handleNavigation">Comparatif logiciels immobiliers</b-link>
                </li>  
                <li>
                  <b-link to="/recherche-immobilier" @click="handleNavigation">Recherche immobilier</b-link>
                </li>       
                <!--<li>Recrutement</li> -->
                <li>
                  <b-link to="/affiliation" @click="handleNavigation">Programme d'affiliation</b-link>
                </li>
              </ul>
            </div>
            <div class="col-md-3">
              <h5>Offres</h5>
              <ul>
                <li>
                  <b-link to="/pricing" @click="handleNavigation">Tarifs</b-link>
                </li>
                <li>
                  <b-link to="/api" @click="handleNavigation">API données immobilières</b-link>
                </li>               

                <li>
                  <b-link to="/generation-de-leads" @click="handleNavigation">Génération de leads</b-link>
                </li>

              </ul>
            </div>
            <div class="col-md-3">
              <h5>Ressources</h5>
              <ul>
                <li>
                  <a target="_blank" rel="noopener" href="https://docs.melo.io/introduction">Documentation API</a>
                </li>
                <li>
                  <div class="cursor-pointer" @click="openChat">Nous contacter</div>
                </li>
                <li>
                  <a  target="_blank" rel="noopener" href="https://melo-io.statuspage.io">Status</a>
                </li>             
              </ul>
            </div>
            <div class="col-md-3">
              <h5>Intégrations</h5>
              <ul>               
                <li>
                  <a target="_blank" rel="noopener" href="https://www.make.com/en/register?pc=virus">Module Make</a>
                </li>
                <li>
                  <a target="_blank" rel="noopener" href="https://zapier.com/apps/melo/integrations">Module Zapier</a>
                </li>             
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import IconVisa from "@/components/icons/IconVisa.vue";
import IconCreditCard from "@/components/icons/IconCreditCard.vue";
import IconMastercard from "@/components/icons/IconMastercard.vue";
export default {
  components: {
    IconMastercard,
    IconVisa,
    IconCreditCard,
  },
  methods: {
    openChat() {
      window.$chatwoot.toggle();
    },
    handleNavigation(event) {
      // Prevent default link behavior
      event.preventDefault();

      // Navigate to the new route
      const href = event.target.closest('a').getAttribute('href');
      const route = href.replace(/^\//, ''); // Remove leading slash

      // Use router.push with a callback to ensure scroll happens after navigation
      this.$router.push({ path: `/${route}` }).then(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
footer {
  * {
    letter-spacing: 1px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  h5 {
    font-weight: bold;
  }
  border-top: 1px solid rgb(223, 223, 223);

  padding: 2rem 1rem 2rem 1rem;
  margin-top: 2rem;

  @include media-breakpoint-up(md) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  ul {
    padding-left: 0;

    li {
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }
  }

  .small-text {
    font-size: 0.8rem;
  }

  .links {
    li {
      opacity: 0.7;
    }
  }
}
</style>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

footer {
  svg {
    height: 2.5rem;
    width: 2.5rem;
    display: block;
    opacity: .2
  }
}
</style>
